
import { ProgramFormula, Phase, Program } from "@/types";
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { programTypes } from '@/variables'
import { ROOT_ACTIONS } from "@/store/actions";
import { Timestamp, collection, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firestore } from "@/firebase";
import { User } from "@/store/user/types";

@Component({
  components: {
    ProgramFormulaSpan: () => import('@/components/typography/ProgramFormulaSpan.vue'),
  }
})

export default class EditProgram extends Vue {
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) program!: Program;
  @Prop({ required: true }) phases!: Phase[];

  programCopy: Partial<Program> = {}

  coaches: User[] = [];
  loading = false;

  phaseDates = {} as Record<string, { start: string, end: string }>;

  async fetchCoaches() {
    const availabeCoaches = await getDocs(query(collection(firestore, 'users'), where('type', 'in', ['COACH', 'ADMIN'])));

    this.coaches = availabeCoaches.docs.map(doc => {
      return {
        id: doc.id,
        ...doc.data(),
        fullName: `${doc.data().firstname} ${doc.data().lastname}`,
      } as User
    });
  }

  rules = {
    date: [
      (v: string) =>
        /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) ||
        "Ongeldig formaat"
    ]
  }


  @Watch('phases', { immediate: true })
  onPhaseChange() {
    this.phaseDates = {}

    this.phases.forEach(phase => {
      // set phase dates to DD/MM/YYYY, phase.start and phase.end are firebase timestamps
      if (!phase.id) return;
      this.phaseDates[phase.id] = {
        start: `${phase.start.toDate().getDate().toString().padStart(2, '0')}/${(phase.start.toDate().getMonth() + 1).toString().padStart(2, '0')}/${phase.start.toDate().getFullYear()}`,
        end: `${phase.end.toDate().getDate().toString().padStart(2, '0')}/${(phase.end.toDate().getMonth() + 1).toString().padStart(2, '0')}/${phase.end.toDate().getFullYear()}`
      }
    })
  }

  async mounted() {
    await this.fetchCoaches();
  }

  @Watch('program', { immediate: true })
  onChange() {
    Object.assign(this.programCopy, {
      ...this.program
    });
  }

  get programFormulas() {
    return Object.keys(ProgramFormula)
      .filter((v) => isNaN(Number(v)))
      .map((name) => {
        return {
          id: ProgramFormula[name as keyof typeof ProgramFormula],
          name,
        };
      });
  }

  _programTypes = programTypes;

  getTimeStampFromString(date: string): Timestamp {
    const dateArray = date.split('/');

    return Timestamp.fromDate(new Date(parseInt(dateArray[2], 10), parseInt(dateArray[1], 10) - 1, parseInt(dateArray[0], 10)));
  }

  async submit() {
    try {
      this.loading = true;
      await updateDoc(doc(firestore, 'programs', this.program.id), this.programCopy)
      for (let phase of this.phases) {
        if(!phase.id) continue;
        await updateDoc(doc(firestore, 'phases', phase.id), {
          start: this.getTimeStampFromString(this.phaseDates[phase.id].start),
          end: this.getTimeStampFromString(this.phaseDates[phase.id].end),
        })
      }
      this.$emit('refresh')
      this.show = false;
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'success',
        text: 'Programma gewijzigd'
      })
    } catch (error) {
      console.log(error)
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: 'error',
        text: error
      })
    } finally {
      this.loading = false;
    }
  }
}
